/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { PASSWORD_MAX_CHARACTERS, PASSWORD_MIN_CHARACTERS } from '../constants';

export function validateUserPassword(password: string) {
    const passwordCount = password.trim().length;
    // TODO: Ensure password includes a special character
    return (
        (passwordCount >= PASSWORD_MIN_CHARACTERS && passwordCount <= PASSWORD_MAX_CHARACTERS) ||
        `Invalid account password. A password should have between ${PASSWORD_MIN_CHARACTERS} and ${PASSWORD_MAX_CHARACTERS} characters.`
    );
}
