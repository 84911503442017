/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

export function emailValidatorReg(email: string) {
    const regExp = RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/);
    return regExp.test(email);
}

export function emailPlusAddressingValidatorReg(email: string) {
    const regExp = RegExp(/^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/);
    return regExp.test(email);
}

export function urlValidatorReg(urlValue: string) {
    const handlerRegex = RegExp(
        /^(http(s)?:\/\/)?([w]{3}\.)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,63}(\/[a-zA-Z0-9\-._~:\/?#[\]@!$&'()*+,;=]*)?$/,
    );
    return handlerRegex.test(urlValue) && !/\s/.test(urlValue);
}

export function phoneNumberValidatorReg(phoneNumber: string) {
    const regex = /^0[2-478](?:[ -]?[0-9]){8}$|^04(?:[ -]?[0-9]){8}$/;
    return regex.test(phoneNumber);
}

export function svgValidatorReg(code: string) {
    return RegExp(/^<svg.*<\/svg>$/).test(code);
}

export function medicareCardNumberValidatorReg(medicare: string) {
    const formatted = medicare.replace(/\s+/g, '');

    // Ensure the number has a minimum of 10 digits and all numbers.
    const regex = /^\d{10,}$/;
    return regex.test(formatted);
}

/**
 * Validates string to ensure its a number.
 *
 * @param value String number value
 * @returns
 */
export function stringNumberValidatorReg(value: string) {
    const regex = /^\d+$/;
    return regex.test(value);
}

export function validateFloatingNumber(value: string) {
    const handlerRegex = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;
    return handlerRegex.test(value);
}

export function isValidSVGReg(svgString: string): boolean {
    // Regular expression to check for SVG structure
    const svgRegex = /<svg[^>]*>([\s\S]*?)<\/svg>/i;

    return svgRegex.test(svgString.trim());
}

export function isValidUUIDReg(uuid: string) {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(uuid);
}
