/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import * as r from 'runtypes';
import { NicotineVapingServiceTitleSchema } from '../constants';
import { DigitalConsultationSchema, PhoneNumberSchema } from '.';
import { ServiceRequestType } from '../constants/checkout';
import { maybe } from '../utils/runtypes';

const NicotineVapingDetailsSchema = r.Record({
    phone: maybe(PhoneNumberSchema),
});

export const NicotineVapingRequestSchema = r.Record({
    title: NicotineVapingServiceTitleSchema,
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.NicotineVaping),
    consultationDetails: NicotineVapingDetailsSchema,
});
export type NicotineVapingRequest = r.Static<typeof NicotineVapingRequestSchema>;
